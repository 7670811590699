import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiKey } from 'react-icons/fi'
import { Avatar, Box, Typography } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import SectionHeader from '../Components/Headers/SectionHeader';
import ActionBtns from '../Components/ActionBtns';
import gridStyle from '../lib/Styles/grid_style';
import ModalOpener from '../Components/ModalOpener';
import { GetAllCustomers } from '../StateMgmt/Actions/customerActions';
import { hasPermission } from '../utils/utils';
import permissions from '../lib/permissions';
import getPermissionData from '../CommonFunctions/getPermissionData';


const Permissions = () => {

    const dispatch = useDispatch()
    // const features = useSelector((state) => state.features);
    const features = JSON.parse(localStorage.getItem('features'))||[];
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [rowData, setRowData] = useState({});
    const [filter, setFilter] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [filterElement, setFilterElement] = useState('');
    const [customerFilter, setCustomerFilter] = useState('');


    useEffect(() => {
        getPermissionData().then((data) => {
            setPermissions(data)
            console.log(data)
            // dispatch(customers)
        });
    }, [openPopup, customerFilter])


    const columns = useMemo(() => [
        // {
        //     field: 'photoURL',
        //     headerName: '',
        //     width: 80,
        //     renderCell: params => <Avatar {...stringAvatar(params.row.name)} />,
        //     sortable: false,
        //     filterable: false,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: 'perm_id',
            headerName: 'Permission ID',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },

        {
            field: 'permissionName',
            headerName: 'Permission',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'functionName',
            headerName: 'Feature',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'desc',
            headerName: 'Description',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'allowedRoles',
            headerName: 'Allowed Roles',
            width: 500,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                const roleNames = params.value.map(role => role.label).join(', ');
                return <span>{roleNames}</span>;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 400,
            renderCell: params =>
                <ActionBtns
                    {...{
                        params,
                        setOpenPopup,
                        setAction,
                        flag: 'Permissions',
                        disableOptions: {
                            edit: true
                        }
                    }}
                />,
            headerAlign: 'center',
            headerClassName: 'table--header',
            align: 'center'
        },
    ], [rowId])
    // rowId is passed as a dependency in useMemo since we've to render the btn if there is a change in the row

    return (

        <>
            <SectionHeader
                icon={<FiKey />}
                headingText='Permissions' btnText="Add Customer"
                setFilter={setFilter}
                data={permissions}
                column='name'
                searchLabel='Search Customer'
                searchFlag={false}
                addBtnFlag={false}
                setOpenPopup={setOpenPopup}
                setAction={setAction}
                action='AddCustomer'
                filter={setCustomerFilter}
                disable={hasPermission(features, 'ADD_CUSTOMER')}

            />
            {/* <SearchDropdown options={columns} setFilterElement={setFilterElement} /> */}
            <Box
                sx={gridStyle}>
                <DataGrid
                    columns={columns}
                    rows={permissions}
                    getRowId={(row) => row.perm_id}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                    onCellEditCommit={params => setRowId(params.id)}
                    onRowClick={params => { setRowId(params.id); setRowData(params.row) }}
                    disableColumnMenu={true}
                    filterModel={{
                        items: filter
                    }}
                />
            </Box>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={rowData}
                    id={rowId}
                />

            }

        </>
    )
}

export default Permissions;

const stringAvatar = (name) => {
    if (!name) return;
    const regex = /\s+/gi;
    const nameLength = name.trim().replace(regex, ' ').split(' ').length;

    return {
        sx: {
            bgcolor: '#153b8c',
        },
        children: nameLength > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name[0]}${name[1]}`
    };
}


