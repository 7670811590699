import {
    Box,
    IconButton,
    Tooltip
} from '@mui/material';

import { Delete, Edit, Preview, FileDownload, AddBox } from '@mui/icons-material';
import { pink, grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { getCustomerDetail } from '../StateMgmt/Actions/customerActions';

const ActionBtns = ({ setOpenPopup, setAction, flag, params, setProductInvoice, disableOptions }) => {

    const navigate = useNavigate();
    const { view, edit, addProduct, remove } = disableOptions || { view: false, edit: false, addProduct: false, remove: false };

    const handleView = async () => {
        const { id, invoice } = params.row;
        if (flag === 'Customer') {
            const { payload } = await getCustomerDetail(id);
            navigate(`/customerdetails/${id}`, { state: payload })
        }
        if (flag === 'Product Invoice') {
            setOpenPopup(true);
            setProductInvoice(invoice)
            setAction('View Product Invoice');
        }
        else {
            setOpenPopup(true);
            setAction('MemberView');
        }
    };

    const handleEdit = () => {
        setOpenPopup(true);
        if (flag === 'Customer') {
            setAction('EditCustomer');
        }
        if (flag === 'Member') {
            setAction('EditMember');
        }
        if (flag === 'Categories') {
            setAction('Editcategory');
        }
        if (flag === 'Brands') {
            setAction('Editbrand');
        }
        if (flag === 'Permissions') {
            setAction('EditPermission');
        }

    }
    const handleRemove = () => {
        setOpenPopup(true);
        if (flag === 'Member') setAction('Remove Member');
    }
    const handleDownloadQR = (url) => {
        const filename = url.split('/').pop();
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        link.click();
    }
    const handleAddProduct = () => {
        setOpenPopup(true);
        setAction('AddProductList');
    }

    return (
        <>
            <Box
                sx={{
                    m: 1,   //margin will be from all side
                    position: 'relative'
                }}
            >
                {(flag === 'Customer' || flag === 'Product Invoice') &&
                    view &&
                    <Tooltip title={`View ${flag} Details`}>
                        <IconButton
                            sx={{
                                width: 40,
                                height: 40,
                                color: '#153b8c',
                                '&:hover': { bgcolor: '#153b8c', color: grey[50] }
                            }}
                            onClick={handleView}>
                            <Preview />
                        </IconButton>
                    </Tooltip>}
                {(flag !== 'QR' && flag !== 'ext_user' && flag !== 'Product Invoice') &&
                    edit &&
                    <Tooltip title={`Edit ${flag} Details`}>
                        <IconButton
                            sx={{
                                width: 40,
                                height: 40,
                                color: '#153b8c',
                                '&:hover': { bgcolor: '#153b8c', color: grey[50] }
                            }}
                            onClick={handleEdit}>
                            <Edit />
                        </IconButton>
                    </Tooltip>}
                {flag === 'Member' &&
                    remove &&
                    < Tooltip title={`Remove ${flag}`}>
                        <IconButton
                            sx={{
                                width: 40,
                                height: 40,
                                color: '#153b8c',
                                '&:hover': { bgcolor: '#153b8c', color: grey[50] }
                            }}
                            onClick={handleRemove}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                }

                {flag === 'QR' &&
                    <Tooltip title={`Download ${flag} CSV`}>
                        <IconButton
                            sx={{
                                width: 40,
                                height: 40,
                                color: '#153b8c',
                                '&:hover': { bgcolor: '#153b8c', color: grey[50] }
                            }}
                            onClick={() => handleDownloadQR(params.row.jsonFileKey)}>
                            <FileDownload />
                        </IconButton>
                    </Tooltip>}
                {flag == 'ext_user' &&
                    addProduct &&
                    <Tooltip title={`Add Product`}>
                        <IconButton
                            sx={{
                                width: 40,
                                height: 40,
                                color: '#153b8c',
                                '&:hover': { bgcolor: '#153b8c', color: grey[50] }
                            }}
                            onClick={handleAddProduct}>
                            <AddBox />
                        </IconButton>
                    </Tooltip>
                }
            </Box>

        </>
    )
}

export default ActionBtns;