import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material//DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material//DialogTitle';
import { removeTeamMember } from '../../StateMgmt/Actions/teamActions';



const RemoveMember = ({ setOpenPopup, memberData, openPopup }) => {
    
      const handleClose = () => {
        setOpenPopup(false);
      };

      const handleSubmit = async () => {
        setOpenPopup(false);
        await removeTeamMember(memberData);
      };
      
    return (
        <div>
          <Dialog
            open={openPopup}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Remove Member
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove member ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="error">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
}

export default RemoveMember