import { styled } from '@mui/material/styles';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, FormHelperText, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useForm } from "react-hook-form";
import BtnOutlined, { BtnDefault } from '../../Components/BtnComponent';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import GetOpenCallFormOptions from '../../CommonFunctions/GetOpenCallFormOptions';
import { getUserAddressId } from '../../CommonFunctions/setAddressId';
import getCustomerAddress from '../../CommonFunctions/getCustomerAddress';
import { addCustomerCall } from '../../StateMgmt/Actions/customerActions';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { errorMessage } from '../../utils/utils';
import ModalOpener from '../ModalOpener';


const OpenCallForm = (props) => {
  const navigate = useNavigate();

  const { setOpenPopup, customerData, setProductInvoice } = props;
  let selectedProduct = props.selectedProduct;
  const { name, phoneNumber, addresses, id } = customerData;
  const [dopValue, setDopValue] = useState(dayjs());
  const [vdtValue, setVdtValue] = useState(dayjs());
  const [billStatus, setBillStatus] = useState([]);
  const [isWarranty, setIsWarranty] = useState(true);
  const [disableWarranty, setDisableWarranty] = useState(false);
  const [productId, setProductId] = useState(null);
  const [natureOfComplaint, setNatureOfComplaint] = useState([]);
  const [productList, setProductList] = useState([]);
  const [serviceCategory, setServiceCategory] = useState([]);
  const [addressId, setAddressId] = useState(addresses[0]?.id);
  const [address, setAddress] = useState('');
  const [query, setQuery] = useState('');
  const [action, setAction] = useState('');
  const [openInvoice, setOpenInvoice] = useState(false);
  // const [openPopup, setOpenPopup] = useState(false);


  useEffect(() => {
    const label = selectedProduct?.label;
    if (label) {
      setQuery(label.substring(0, 4))
      setProductId(selectedProduct.productId)
    };
    GetOpenCallFormOptions(query).then(({ billSatus, natureOfComplain, product, serviceCategory }) => {
      setBillStatus(billSatus)
      setNatureOfComplaint(natureOfComplain)
      setProductList(product)
      setServiceCategory(serviceCategory)
      setAddressId(getUserAddressId());
      getCustomerAddress(getUserAddressId()).then(res => {
        setAddress(res)
      });
    });
  }, [query])

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();

  const handleWarranty = (_, value, reason) => {
    if (value.label === 'Not Available') {
      setIsWarranty(false);
      setDisableWarranty(true);
    } else {
      setDisableWarranty(false);
    }
  }

  const handleWarrantyChange = (_, value, reason) => {
    setIsWarranty(value);
  }

  const handleSelectProduct = (_, value, reason) => {
    setProductId(value.id);
  }
  const handleAutocompleteTextChange = (event) => {
    const productQuery = event.target.value;
    setProductList([])
    setQuery(productQuery);
  }

  const handleView = async () => {
    setOpenInvoice(true);
    setProductInvoice(selectedProduct.invoice)
    setAction('Show Product Invoice');

  };

  const onSubmit = async (data) => {
    console.log(data);
    const reqBody = data;
    reqBody.dateOfPurchase = dayjs(data.dateOfPurchase).format('YYYY-MM-DD');
    reqBody.visitDateTime = dayjs(vdtValue).format();
    reqBody.noOfProduct = +data.noOfProduct;
    reqBody.addressId = +addressId;
    reqBody.isWarranty = isWarranty;
    reqBody.customerId = id;
    reqBody.productId = productId;
    reqBody.billStatus = data.billStatus || 'NA';
    if (productId === selectedProduct.productId) reqBody.customerProductID = selectedProduct.id;
    if (!productId) errorMessage('No product selected')
    else {
      await addCustomerCall(reqBody);
      setOpenPopup(false);
      window.myGlobalVariable = { user: (window.myGlobalVariable?.reload ?? 0)+1};
      navigate('/complaints');
    }

  }


  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <div className='flex flex-col gap-3'>
          <CustomTextField
            disabled
            className='w-full'
            defaultValue={name}
            variant='outlined'
            label='Customer Name'
            name='firstName'
          />
          <CustomTextField
            disabled
            className='w-full'
            defaultValue={phoneNumber}
            variant='outlined'
            label='Phone'
            name='phone'
          />
        </div>

        <CustomTextField
          disabled
          defaultValue={address}
          variant='outlined'
          multiline
          rows={4}
          // label='Customer Address'
          name='address'
        />

      </div>
      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <Stack spacing={2} sx={{ color: '#851851' }}>
          <Autocomplete
            error={Boolean(errors.role)}
            disableClearable
            onChange={handleSelectProduct}
            options={productList}
            defaultValue={_.isEmpty(selectedProduct) ? productList[0] : selectedProduct}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label='Select Product'
                name='product'
                // {...register("product", { required: 'Please select a product' })}
                // error={Boolean(errors.product)}
                // helperText={errors.product?.message}
                onChange={handleAutocompleteTextChange}
              />
            )}

          />
        </Stack>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date of Purchase"
            value={dopValue}

            format="DD-MM-YYYY"

            onChange={(newValue) => {
              setDopValue(newValue);
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                name='dateOfPurchase'
                {...register("dateOfPurchase")}
              // error={Boolean(errors.dateOfPurchase)}
              // helperText={errors.dateOfPurchase?.message}
              />
            )}
            maxDate={dayjs()}
          />
        </LocalizationProvider>
      </div>
      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <CustomTextField
          className='w-full'
          placeholder='Number of Products'
          defaultValue={1}
          variant='outlined'
          label='Number of Products'
          name='noOfProduct'
          {...register("noOfProduct")}
        // error={Boolean(errors.noOfProduct)}
        // helperText={errors.noOfProduct?.message}
        />
        <CustomTextField
          className='w-full'
          placeholder='Enter Dealer Name'
          defaultValue={selectedProduct?.DealerName}
          variant='outlined'
          label='Dealer Name'
          name='dealerName'
          {...register("dealerName")}
        // error={Boolean(errors.dealerName)}
        // helperText={errors.dealerName?.message}
        />
      </div>
      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <Stack spacing={2} sx={{ color: '#851851' }}>
          <Autocomplete
            error={Boolean(errors.role)}
            disableClearable
            defaultValue={{ label: 'NA' }}
            onChange={handleWarranty}
            options={billStatus}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                label='Bill Status'
                name='billStatus'
                {...register("billStatus")}
              // error={Boolean(errors.billStatus)}
              // helperText={errors.billStatus?.message}
              />
            )}

          />
        </Stack>
        <FormControl error={Boolean(errors.active)}>
          <FormLabel className='flex flex-start text-ashghal-red'>Warranty</FormLabel>
          <RadioGroup
            row={true}
            name='warranty'
            value={isWarranty}
            onChange={handleWarrantyChange}
            className='flex justify-between'
          >
            <div>
              <FormControlLabel disabled={disableWarranty} value={true} control={<Radio sx={radioCss} />} label='Yes' />
              <FormControlLabel disabled={disableWarranty} value={false} control={<Radio sx={radioCss} />} label='No' />
            </div>
            {selectedProduct &&
              <span class="text-blue-500 hover:underline cursor-pointer mr-5" onClick={handleView}>View Invoice</span>
            }

            {/* <span className='mr-5'>View Invoice</span> */}


          </RadioGroup>
          <FormHelperText>{errors.active?.message}</FormHelperText>
        </FormControl>
      </div>
      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <div className='flex flex-col gap-3'>
          <Stack spacing={2} sx={{ color: '#851851' }}>
            <Autocomplete
              error={Boolean(errors.role)}
              disableClearable
              // onChange={handleSelectRole}
              options={natureOfComplaint}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label='Nature of Complaint'
                  name='natureOfComplain'
                  {...register("natureOfComplain")}
                // error={Boolean(errors.natureOfComplain)}
                // helperText={errors.natureOfComplain?.message}
                />
              )}

            />
          </Stack>
          <Stack spacing={2} sx={{ color: '#851851' }}>
            <Autocomplete
              error={Boolean(errors.role)}
              disableClearable
              // onChange={handleSelectRole}
              options={serviceCategory}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  label='Product Servicing Category'
                  name='service'
                // {...register("service", { required: 'Please select a Servicing Category' })}
                // error={Boolean(errors.service)}
                // helperText={errors.service?.message}
                />
              )}

            />
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Visiting Date/Time"
              value={vdtValue}
              onChange={(newValue) => {
                setVdtValue(newValue);
              }}
              minDate={dayjs()}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  name='visitDateTime'
                />
              )}
            />
          </LocalizationProvider>

        </div>

        <CustomTextField
          placeholder="Describe your request"
          variant='outlined'
          multiline
          rows={7}
          label='Request Description'
          name='jobDescription'
          {...register("jobDescription", { required: false })}
        />

      </div>

      <div className="grid gap-4 grid-cols-2 gap-x-8 ">
        <BtnDefault
          variant="contained"
          color="primary"
          size="medium"
          text="Open New Call"
          type="submit"
        />
        <BtnOutlined
          variant="outlined"
          color="error"
          size="medium"
          text="Cancel"
          border='#016fe2'
          onClick={() => setOpenPopup(false)}
        />
      </div>
      {action && openInvoice &&
        <ModalOpener
          action={action}
          openInvoice={openInvoice}
          setOpenInvoice={setOpenInvoice}
          // data={rowData}
          // id={rowId}
          ProductInvoice={selectedProduct.invoice}
        />
      }
    </form>

  )
}

export default OpenCallForm

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#153b8c',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#153b8c',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#016fe2',
    },
    '&:hover fieldset': {
      borderColor: '#153b8c',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#153b8c',
    },
  },
});

const radioCss = {
  color: '#153b8c',
  '&.Mui-checked': {
    color: '#153b8c',
  },
};
