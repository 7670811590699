import React, { useState, useEffect } from 'react'
import { FaUserTie } from 'react-icons/fa';
import { HiMenuAlt3 } from 'react-icons/hi'
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { DASHBOARD_SIDEBAR_LINKS } from '../lib/navigation';
import { getUser } from '../utils/utils';
import { hasPermission } from '../utils/utils';
import { useSelector } from 'react-redux';
import api from "../utils/axios";
import moment from 'moment';

const Exp_Sidebar = () => {
    const [open, setOpen] = useState(false);
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    // const permissions = useSelector((state) => state.permissions);
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    const [planName, SetPlanName] = useState('');
    const [planExp, SetPlanExp] = useState('');
    const [qrCount, SetQrCount] = useState({used: 0, limit: 0});
    const [complaintCount, SetComplaintCount] = useState({used: 0, limit: 0});
    console.log(permissions)
    useEffect(() => {
        const userData = getUser()
        if (!_.isEmpty(userData)) {
            setUserName(userData.name);
            setUserRole(userData.role);
            setIsAdmin(userData.role === 'Admin');
        }
        api.get('/api/v1/user/getActivePlan').then((data)=>{
            const {plan, validUpto, complaintCount, qrRegistrationCount} = data.data.data;
            SetPlanName(plan);
            SetPlanExp(validUpto);
            SetQrCount(qrRegistrationCount);
            SetComplaintCount(complaintCount);
        });
        // const { name, role } = JSON.parse(localStorage.getItem('userData'));
        // setUserName(name);
        // setUserRole(role);
    }, [window.myGlobalVariable?.reload])


    return (
        <div className={`bg-themeBlue-1 min-h-[95vh] ${open ? 'w-80' : 'w-16'} duration-500 text-gray-100 px-4`}>
            <div className='py-3 flex justify-end'>
                <HiMenuAlt3 size={30} className='cursor-pointer' onClick={() => setOpen(!open)} />
            </div>
            <div className={`flex items-center space-x-3 pl-1 py-3 whitespace-pre duration-500`}>
                <div className='flex'>
                    <FaUserTie fontSize={30} />
                </div>

                <div className={`flex flex-col space-y-1 whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'}`}>
                    <span className={`text-neutral-100 text-lg font-semibold`}>{userRole}</span>
                    <span className={`text-neutral-100 text-sm font-semibold`}>{userName}</span>
                </div>
            </div>


            {/* {userRole == 'Admin' &&
                <div className='mt-4 flex flex-col gap-4 relative'>
                    {DASHBOARD_SIDEBAR_LINKS.map((sidebar_items, i) => (
                        sidebar_items.admin && <SidenavItems key={sidebar_items.key} item={sidebar_items} open={open} i={i} />
                    ))}
                </div>
            } */}
            {/* {userRole == 'Admin' && */}
                <div className='mt-4 flex flex-col gap-4 relative'>
                    {DASHBOARD_SIDEBAR_LINKS.filter((x)=>{
                       return  hasPermission(permissions, x.permissionName)
                    }).map((sidebar_items, i) => (
                    <SidenavItems key={sidebar_items.key} item={sidebar_items} open={open} i={i} />
                    ))}
                </div>
            {/* } */}

            {/* {userRole == 'Customer Executive' &&
                <div className='mt-4 flex flex-col gap-4 relative'>
                    {DASHBOARD_SIDEBAR_LINKS.map((sidebar_items, i) => (
                        sidebar_items.customer && <SidenavItems key={sidebar_items.key} item={sidebar_items} open={open} i={i} />
                    ))}
                </div>
            }
            {userRole == 'Data Entry' &&
                <div className='mt-4 flex flex-col gap-4 relative'>
                    {DASHBOARD_SIDEBAR_LINKS.map((sidebar_items, i) => (
                        sidebar_items.operator && <SidenavItems key={sidebar_items.key} item={sidebar_items} open={open} i={i} />
                    ))}
                </div>
            } */}
            {/* { window.innerHeight >= 555 &&
                <div className={`flex items-center space-x-3 pl-1 py-3 whitespace-pre duration-500 absolute bottom-9`}>
                <div className={`flex flex-col space-y-1 whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'}`}>
                    <span className={`text-neutral-100 text-lg font-semibold`}>{planName}{' Plan'}</span>
                    <span className={`text-neutral-100 text-sm font-semibold`}>{'Valid Upto: '} {moment(planExp).format('MMM Do YY')}</span>
                    <span className={`text-neutral-100 text-sm font-semibold`}>{'Complaint Usage: '} {complaintCount.used}{'/'}{complaintCount.limit}</span>
                    <span className={`text-neutral-100 text-sm font-semibold`}>{'Qr Usage: '} {qrCount.limit > 0 ? `${qrCount.used} / ${qrCount.limit}`: 'NA'}</span>
                </div>
                </div>
            } */}
        


            {/* <div className='mt-4 flex flex-col gap-4 relative'>
                {DASHBOARD_SIDEBAR_LINKS.map((sidebar_items, i) => (
                    <SidenavItems key={sidebar_items.key} item={sidebar_items} open={open} i={i} />
                ))}
            </div> */}
        </div>
    )
}

export default Exp_Sidebar;

const SidenavItems = ({ i, item, open }) => {
    const permissions = useSelector((state) => state.permissions);

    const styleClass = `group flex items-center text-md gap-3.5 font-medium p-2 hover:bg-themeBlue-2 hover:pr-8 hover:no-underline rounded-md`;
    const labelStyle = `text-gray-100 whitespace-pre duration-500 ${!open && 'opacity-0 translate-x-28 overflow-hidden'}`;

    return (

        <Link to={item.path} className={classNames(item.margin && 'mt-80', styleClass)}>
            <span className='text-2xl text-gray-100'>{item.icon}</span>
            <h2 style={{ transitionDelay: `${i + 3}00ms` }} className={labelStyle}>{item.label}</h2>
            <h2 className={`${open && 'hidden'} z-10 absolute left-48 bg-themeBlue-2 font-medium whitespace-pre text-gray-100 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>
                {item.label}
            </h2>
        </Link>
    )



}

// {
//     hasPermission(permissions, 'view_reports') && (
//         <div>
//             <h2>Reports Section</h2>
//             {/* Render reports component or section */}
//         </div>
//     )
// }

// return (
//     <Link to={item.path} className={classNames(item.margin && 'mt-80', styleClass)}>
//         <span className='text-2xl text-gray-100'>{item.icon}</span>
//         <h2 style={{ transitionDelay: `${i + 3}00ms` }} className={labelStyle}>{item.label}</h2>
//         <h2 className={`${open && 'hidden'} z-10 absolute left-48 bg-themeBlue-2 font-medium whitespace-pre text-gray-100 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>
//             {item.label}
//         </h2>
//     </Link>
// )