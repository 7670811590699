import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiCategoryAlt } from "react-icons/bi";
import { Avatar, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SectionHeader from '../Components/Headers/SectionHeader';
import ActionBtns from '../Components/ActionBtns';
import gridStyle from '../lib/Styles/grid_style';
import ModalOpener from '../Components/ModalOpener';
import { GetProductBrands, GetProductCategories } from '../StateMgmt/Actions/productActions';
import { hasPermission } from '../utils/utils';



const ProductBrands = () => {
    const dispatch = useDispatch()
    // const features = useSelector((state) => state.features);
    const features = JSON.parse(localStorage.getItem('features'))||[];
    const [productBrands, setProductBrands] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [rowData, setRowData] = useState({});
    const [filter, setFilter] = useState([]);


    useEffect(() => {
        GetProductBrands().then((data) => {
            setProductBrands(data.payload.data)
            dispatch(productBrands)
        });
    }, [openPopup])

    const columns = useMemo(() => [
        {
            field: 'id',
            headerName: 'Brand ID',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },

        {
            field: 'brandName',
            headerName: 'Brand',
            width: 400,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 400,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 400,
            renderCell: params =>
                <ActionBtns
                    {...{
                        params,
                        setOpenPopup,
                        setAction,
                        flag: 'Brands',
                        disableOptions: {
                            edit: hasPermission(features, 'EDIT_CATEGORY_SUBCATEGORY')
                        }
                    }}
                />,
            headerAlign: 'center',
            headerClassName: 'table--header',
            align: 'center'
        },
    ], [rowId])
    // rowId is passed as a dependency in useMemo since we've to render the btn if there is a change in the row
    return (

        <>
            <SectionHeader
                icon={<BiCategoryAlt />}
                headingText='Product Brands' btnText="Create Brand"
                setFilter={setFilter}
                // data={customers}
                column='name'
                searchLabel='Search Brand'
                searchFlag={true}
                addBtnFlag={true}
                setOpenPopup={setOpenPopup}
                setAction={setAction}
                action='CreateBrand'
                disable={hasPermission(features, 'ADD_CATEGORY_SUBCATEGORY')}
            // filter={setCustomerFilter}
            />
            {/* <SearchDropdown options={columns} setFilterElement={setFilterElement} /> */}
            <Box
                sx={gridStyle}>
                <DataGrid
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    rows={productBrands}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                    onCellEditCommit={params => setRowId(params.id)}
                    onRowClick={params => { setRowId(params.id); setRowData(params.row) }}
                    disableColumnMenu={true}
                    filterModel={{
                        items: filter
                    }}
                />
            </Box>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={rowData}
                    id={rowId}
                />

            }

        </>
    )
}

export default ProductBrands;

