import { successResponse } from '../../utils/utils';
import api from "../../utils/axios";

export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_FEATURES = 'GET_FEATURES';

export const getUserPermissions = () => {
  return async (dispatch) => {
    let url = '/api/v1/user/getPermissions';
    const res = await api.post(url);
    localStorage.setItem('permissions', JSON.stringify(res.data.data));
    dispatch({
      type: GET_PERMISSIONS,
      payload: res.data.data,
    });
  };
};

export const getFeaturePermissions = () => {
  return async (dispatch) => {
    let url = '/api/v1/user/getFeatures';
    const res = await api.post(url);
    localStorage.setItem('features', JSON.stringify(res.data.data));
    dispatch({
      type: GET_FEATURES,
      payload: res.data.data,
    });
  };
};
