import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlineUserGroup } from "react-icons/hi";
import { Avatar, Box, Button } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import SectionHeader from '../Components/Headers/SectionHeader';
import ActionBtns from '../Components/ActionBtns';
import gridStyle from '../lib/Styles/grid_style';
import ModalOpener from '../Components/ModalOpener';
import { GetQRCustomers } from '../StateMgmt/Actions/customerActions';
import external_user from '../lib/customer_record';
import { hasPermission } from '../utils/utils';

const ExternalUsers = () => {

    const dispatch = useDispatch()
    // const features = useSelector((state) => state.features);
    const features = JSON.parse(localStorage.getItem('features'))||[];
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [rowData, setRowData] = useState({});
    const [filter, setFilter] = useState([]);
    const [qrCustomers, setQRCustomers] = useState([]);
    const [userFilter, setUserFilter] = useState('');


    useEffect(() => {
        GetQRCustomers(userFilter).then((data) => {
            setQRCustomers(data.payload.data.filter(data => data.sessionId))
            dispatch(qrCustomers)
        });
    }, [openPopup, userFilter])

    const columns = useMemo(() => [
        {
            field: 'actions',
            headerName: 'Actions',
            width: 80,
            renderCell: params =>
                <ActionBtns
                    {...{
                        params,
                        setOpenPopup,
                        setAction,
                        flag: 'ext_user',
                        disableOptions: {
                            addProduct: hasPermission(features, 'QR_ADD_PRODUCT'),
                        }
                    }}
                />,
            headerAlign: 'center',
            headerClassName: 'table--header',
            align: 'center'
        },
        {
            field: 'customerId',
            headerName: 'Customer ID',
            width: 120,
            headerAlign: 'center',
            align: 'center'
        },

        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 300,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'OtherBrandProduct',
            headerName: 'Other Brand Product',
            width: 300,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'dealerName',
            headerName: 'Dealer',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'registrationDate',
            headerName: 'Register Date',
            width: 150,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'view_invoice',
        //     headerName: 'Invoice',
        //     width: 200,
        //     // renderCell: params => <a href={params.row.invoice} target='_blank' className='text-md font-bold text-[#153b8c]'>View Invoice</a>,
        //     renderCell: params => <Button onClick={() => window.open('/open-invoice/${params.row.invoice}')}>View Invoice</Button>,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
    ], [rowId])
    // rowId is passed as a dependency in useMemo since we've to render the btn if there is a change in the row
    return (
        // '/auth/change-password'
        <>
            <SectionHeader
                icon={<HiOutlineUserGroup />}
                headingText='QR Registrations'
                data={external_user}
                column='name'
                btnText="Download Generic QR"
                genericQR={true}
                searchFlag={true}
                searchLabel='Search User'
                addBtnFlag={true}
                setOpenPopup={setOpenPopup}
                setAction={setAction}
                filter={setUserFilter}
                disable={hasPermission(features, 'ADD_CUSTOMER')}
            />

            <Box
                sx={gridStyle}>
                <DataGrid
                    sx={dataGridStyle}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    rows={qrCustomers}
                    getRowId={(row) => row.sessionId}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                    onCellEditCommit={params => setRowId(params.id)}
                    onRowClick={params => { setRowId(params.id); setRowData(params.row) }}
                    disableColumnMenu={true}
                    filterModel={{
                        items: filter
                    }}
                    getRowClassName={(params) =>
                        params.row.processed ? "processed" : "unprocessed"
                    }
                />
            </Box>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={rowData}
                    id={rowId}
                />

            }

        </>
    )
}

export default ExternalUsers;

const stringAvatar = (name) => {
    if (!name) return;
    const regex = /\s+/gi;
    const nameLength = name.trim().replace(regex, ' ').split(' ').length;

    return {
        sx: {
            bgcolor: '#153b8c',
        },
        children: nameLength > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name[0]}${name[1]}`
    };
}

const dataGridStyle = {
    [`& .${gridClasses.row}.processed`]: {
        backgroundColor: "#d0d8e8",
    },
    [`& .${gridClasses.row}.unprocessed`]: {
        backgroundColor: "#fff",
    },

    // ["& .assignStatus--cell"]: {
    //     color: "#851851",
    //     fontWeight: "600",
    //     textAlign: "center",
    // },
};
