import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import permissionsReducer, { featureReducer } from './Reducers/permissionReducer';

const rootReducer = combineReducers({
    permissions: permissionsReducer,
    features: featureReducer
    // Add other reducers here if needed
});

const store = createStore(rootReducer, applyMiddleware(thunk));


export default store;
