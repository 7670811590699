
import { GET_PERMISSIONS } from "../Actions/permissionActions";
import { GET_FEATURES } from "../Actions/permissionActions";

const initialPermissions = [];
const initialFeatures = [];

const permissionReducer = (state = initialPermissions, action) => {
    switch (action.type) {
        case GET_PERMISSIONS:
            return action.payload;
        default:
            return state;
    }
}

export const featureReducer = (state = initialFeatures, action) => {
    switch (action.type) {
        case GET_FEATURES:
            return action.payload;
        default:
            return state;
    }
}

export default permissionReducer;