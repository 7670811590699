import { toast } from 'react-toastify';


// return the user data from the local Storage
export const getUser = () => {
    const userStr = localStorage.getItem('userData');
    if (userStr) {
        return JSON.parse(userStr);
    }
    else return null;
}

// return the token from the local Storage
export const getToken = () => {
    return localStorage.getItem('token') || null;
}

// remove the token and user from the local Storage
export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}

// set the token and user from the local Storage
export const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
}

//  Get Username
export const getUserName = () => {
    const username = JSON.parse(localStorage.getItem('user')).username;
    if (username) return username;
    else return null;
}

// Get User Id
export const getUserId = () => {
    const userId = JSON.parse(localStorage.getItem('user')).userId;
    if (userId) return userId;
    else return null;
}

export const hasPermission = (permissions, requiredPermission) => {
    if (Array.isArray(permissions)) {
        console.log(permissions);
        console.log(permissions.includes(requiredPermission))
        return permissions.includes(requiredPermission);

    }
}

export const successResponse = (message) => {
    toast.success(message, {
        position: "top-center",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    });
}

export const errorMessage = (message) => {
    toast.error(message, {
        position: "top-center",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    });
}

