import React from 'react';
import { Outlet } from 'react-router-dom';
// import Logo from '../../Images/logo.png'
import Logo from '../../Images/Sahayak Logo-1.png';
import Sahayak_Logo from '../../Images/Sahayak Logo-1.png'
import Footer from '../Footer';
import Cookies from 'js-cookie';
const logoStyle = {
    height: '80px', // Adjust this value to control the height
    width: 'auto', // This keeps the aspect ratio of the image
};



const GrafdoerLayout = () => {
    const logo =  Cookies.get('logo') || Logo
    return (
        <div className=''>
            <div className='flex h-full w-full bg-pack-train bg-cover'>
                <header className="fixed w-full">
                    <div className="mx-auto w-full py-3 px-3 sm:px-6 lg:px-8">
                        <div className="pt-8 sm:px-16 sm:text-center">
                            <div className='flex flex-col pb-2 items-center justify-center '>
                                <img className='' src={logo} alt="" style={logoStyle} />
                                <div className='flex items-end pb-2 gap-1 font-extralight text-xs'>
                                    <span className='pb-1'>Powered by </span>
                                    <img className='h-7' src={Sahayak_Logo} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='bg-themeBlue-4 dark:bg-gray-600 w-full h-0.5 mt-2'></div>
                    </div>
                </header>
                <div className='flex flex-col z-10 w-full'>
                    <div className='mt-0 relative'>{<Outlet />}</div>
                </div>
            </div>
            <div>
                {/* <Footer /> */}
            </div>
        </div>

    )
}

export default GrafdoerLayout