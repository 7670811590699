import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { BsFillKeyFill } from "react-icons/bs";
import { TextField, Typography } from '@mui/material';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { addNewProductCategory, addProductBrand, editProductBrand } from '../../StateMgmt/Actions/productActions';
import getRoles from '../../CommonFunctions/getRoles';
import updatePermission from '../../CommonFunctions/updatePermission';

const EditPermission = ({ setOpenPopup, currentData }) => {

    const { allowedRoles, functionId } = currentData;
    const [roles, setRoles] = useState(allowedRoles);
    const [roleIdArray, setRoleIdArray] = useState([]);
    const [roleList, setRoleList] = useState([]);

    const deleteFnRef = useRef(null);

    useEffect(() => {
        getRoles().then(data => {
            console.log(roles);
            const temp = roles.map(role => { return role.id })
            setRoleIdArray(temp)
            const tempRole = data.filter(role => !(temp || []).includes(role.id))
            setRoleList(tempRole)
        });
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const handleSelectRoles = (_, value, reason) => {
        console.log(value)
        const temp = value.map(x => { return x.id })
        const temp2 = roleList.find(y => { return !temp.includes(y.id) })
        console.log(temp2);

        setRoles([...roles, temp2])
        console.log(roles);
        setRoleList(value);
        setRoleIdArray([...roleIdArray, temp2.id])
        // const role_id = []
        // value.map((v_obj) => {
        //     role_id.push(v_obj.id)
        // })
        // setRoleIdArray(role_id);
    }

    //Function to get the delete function from reference tags
    const getDeleteFnObj = (value, getTagProps) => {           //getTagProps returns the reference of onDelete function
        const deleteFnRefObj = {}

        //value is an array of selected permissions
        value.forEach((tag, index) => {
            deleteFnRefObj[index] = getTagProps({ index }).onDelete   // saving ref of delete fn w.r.t the index of each permission
        });

        deleteFnRef.current = deleteFnRefObj;
        return null;
    }

    const handleRemoveRole = (role) => {
        
        const index = roles.findIndex(r => r.id === role.id);
        deleteFnRef.current[index]();

        let arr = [...roles]
        if (index !== -1) {
            arr.splice(index, 1)
        }
        let permissionsArr = [...roleList, role]
        setRoles(arr)
        setRoleList(permissionsArr)
        setRoleIdArray(arr.map(x=>x.id))
    }

    const onSubmit = async (data) => {
        const reqBody = {
            functionId: functionId,
            allowedRoles: roleIdArray
        };
        console.log(reqBody)
        await updatePermission(reqBody)
        setOpenPopup(false);
        // dispatch(resData.payload);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>

            <div className='flex flex-col'>
                <Stack sx={{ color: '#851851' }} >
                    <Autocomplete
                        multiple
                        options={roleList}
                        disableClearable={true}
                        getOptionSelected={(option, value) => option.label === value.label}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        value={roleList}
                        onChange={handleSelectRoles}
                        renderTags={getDeleteFnObj}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Add Roles'
                            />
                        )}
                    />
                </Stack>
                <div className='flex flex-col mt-8 mb-4 overflow-y-auto h-36 space-y-3'>
                    {!roles.length ?
                        <Typography variant='button' color='#153B8C'>No Roles Selected</Typography>
                        : roles.map((role) => (
                            <div className='grid grid-cols-10'>
                                <div className='col-span-1 text-[#153B8C] text-2xl flex place-items-center'>
                                    <BsFillKeyFill />
                                </div>
                                <div className="col-start-2 col-span-7 flex place-content-start place-items-center">
                                    <div className='flex'>{role.label}</div>
                                </div>
                                <div className='col-span-2 flex justify-end'>
                                    <BtnOutlined
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        text="Remove"
                                        onClick={() => handleRemoveRole(role)}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>



            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Submit"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>

        </form>
    )
}

export default EditPermission