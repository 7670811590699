import React, { useEffect, useState } from 'react';

import your_logo from '../../Images/grafdoer.jpg'
// import your_logo from '../../Images/logo.png'
import Logo from '../../Images/Sahayak Logo-1.png'
import { getUser } from '../../utils/utils';
import Popup from '../Popup';

const DashboardHeader = () => {

    const logoStyle = {
        height: '80px', // Adjust this value to control the height
        width: 'auto', // This keeps the aspect ratio of the image
    };
    const [logoUrl, setLogoUrl] = useState('');
    useEffect(() => {
        const userData = getUser()
        setLogoUrl(userData?.logo);
    }, [])
    return (
        <div className='flex flex-col'>
            <div className='h-17 flex items-center justify-between lg:px-4'>
                <div className='flex'>
                    <img src={logoUrl} alt='Company Logo' style={logoStyle} />
                    <div className='lg:flex items-end pb-2 gap-1 font-extralight text-xs hidden'>
                        <span className='pb-1'>Powered by </span>
                        <img className='h-7' src={Logo} alt="" />
                    </div>
                </div>
                
                <div className='flex items-center z-10'>
                    <Popup tooltip='Profile' eventHandler={() => console.log('Handle Profile')} reload={new Date()} />
                </div>
            </div>
            <div className='bg-themeBlue-4 dark:bg-gray-600 w-full h-0.5 mt-2'></div>
        </div>


    )
}

export default DashboardHeader