import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import Autocomplete from '@mui/material/Autocomplete';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import GetProductFormOptions from '../../CommonFunctions/GetProductFormOptions';
import { uploadImage } from '../../CommonFunctions/GetStepperData';
import { addNewProduct } from '../../StateMgmt/Actions/productActions';
import _ from 'lodash';
import { errorMessage } from '../../utils/utils';


const Add_product = ({ setOpenPopup }) => {

    const dispatch = useDispatch()

    const [options, setOptions] = useState([]);
    const [range, setRange] = useState([]);
    const [brand, setBrand] = useState([]);
    const [service, setService] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [source, setSource] = useState('');
    const [categoryId, setCategoryId] = useState(0);
    const [subCategoryId, setSubCategoryId] = useState(0);
    const [rangeId, setRangeId] = useState(0);
    const [brandId, setBrandId] = useState(0);
    const [services, setServices] = useState([]);
    const [imageReader, setImageReader] = useState('');

    useEffect(() => {
        GetProductFormOptions().then(({ category, range, brand, service }) => {
            setOptions(category);
            setRange(range);
            setBrand(brand);
            setService(service)
        });
    }, [])

    const handleFile = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setImageReader(reader);
        previewFile(reader);
    }

    const previewFile = (reader) => {
        reader.onloadend = () => {
            setSource(reader.result)
        }
    }

    const getSubCategories = (value) => {
        const subCategory = value.ProductSubCategories.map(obj => {
            obj.label = obj.subCategoryName;
            return obj;
        });
        setSubCategories(subCategory);
        setCategoryId(value.id);
    }


    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onSubmit = async (data) => {
        console.log(data)
        if(!imageReader) {
            errorMessage('Please upload a product image!');
            return
        }
        const reqBody = data;
        reqBody.ProductCategoryId = categoryId;
        reqBody.ProductSubCategoryId = subCategoryId;
        reqBody.ProductRangeId = data.range;
        reqBody.BrandId = brandId;
        reqBody.serviceOffered = services;
        const { imageUrl } = await uploadImage(imageReader, 'Product');
        reqBody.image = imageUrl;
        const resData = await addNewProduct(reqBody)
        setOpenPopup(false)
        dispatch(resData.payload);

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <Stack spacing={2} sx={{ color: '#851851' }}>
                    <Autocomplete
                        options={options}
                        onChange={(e, value) => { getSubCategories(value) }}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Select Product Category'
                                name='category'
                                {...register("category", { required: 'Please select a category' })}
                                error={Boolean(errors.category)}
                                helperText={errors.category?.message}
                            />
                        )}

                    />
                </Stack>
                <Stack spacing={2} sx={{ color: '#851851' }}>
                    <Autocomplete
                        options={subCategories}
                        onChange={(e, value) => { setSubCategoryId(value.id) }}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Select Product Sub-Category'
                                name='subcategory'
                                {...register("subcategory", { required: 'Please select a sub category' })}
                                error={Boolean(errors.subcategory)}
                                helperText={errors.subcategory?.message}
                            />
                        )}

                    />
                </Stack>



            </div>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                {/* <Stack spacing={2} sx={{ color: '#851851' }}>
                    <Autocomplete
                        options={range}
                        onChange={(e, value) => { setRangeId(value.id) }}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Select Product Range'
                                name='range'
                                {...register("range", { required: 'Please select a range' })}
                                error={Boolean(errors.range)}
                                helperText={errors.range?.message}
                            />
                        )}

                    />
                </Stack> */}
                <CustomizeTextField
                    label='Enter Product Range/Name'
                    name='range'
                    {...register("range", { required: 'Please enter product range' })}
                    error={Boolean(errors.range)}
                    helperText={errors.range?.message}
                />
                <Stack spacing={2} sx={{ color: '#851851' }}>
                    <Autocomplete
                        options={brand}
                        onChange={(e, value) => { setBrandId(value.id) }}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Select Product Brand'
                                name='brand'
                                {...register("brand", { required: 'Please select a brand' })}
                                error={Boolean(errors.brand)}
                                helperText={errors.brand?.message}
                            />
                        )}

                    />
                </Stack>

            </div>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">

                <Stack spacing={2} sx={{ color: '#851851' }}>
                    <Autocomplete
                        multiple
                        onChange={(event, value) => {
                            setServices(value)
                        }}
                        options={service}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Add services for the product'
                                name='services'
                            />
                        )}

                    />
                </Stack>
                <CustomizeTextField
                    
                    placeholder='Please enter your state'
                    variant='outlined'
                    label='Add product warranty (in months)'
                    name='warranty'
                    {...register("warranty", { required: 'Please add warranty' })}
                    error={Boolean(errors.warranty)}
                    helperText={errors.warranty?.message}
                />

            </div>
            <div className=''>

                <Stack spacing={2} sx={{ color: '#851851' }} className='w-full'>


                    <div class="flex items-center justify-start w-full">
                        <label for="dropzone-file" class="flex flex-col items-start justify-center pl-4 w-full h-16 border-2 border-gray-300 hover:border-[#016FE2] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div className="flex justify-start gap-3 pt-8 pb-6">
                                <svg aria-hidden="true" class="w-8 h-8 mb-3 text-[#016FE2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>
                                <div className='flex flex-col'>
                                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Product Image</span></p>
                                    <p class="mb-2 text-xs text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                </div>
                                {source && (
                                    <div className='bg-themeBlue-5 mb-2'>
                                        <img className='w-16 h-12' src={source} alt='image' />
                                    </div>
                                )}
                            </div>
                            <input
                                id="dropzone-file"
                                type="file"
                                class="hidden"
                                onChange={(e) => {
                                    handleFile(e)
                                    setImageReader(e.target.files[0]);
                                }}
                                
                            />
                        </label>

                    </div>
                    <p class="text-xs text-end text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                </Stack>


            </div>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Submit"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>
        </form>
    )
}

export default Add_product