import api from "../utils/axios";
import getRoles from "./getRoles";

const getPermissionData = async () => {
    const response = await api.get('/api/v1/user/permissionFeature');
    const temp = response.data?.data;
    const permissions = temp.map((item, index) => ({
        ...item,
        perm_id: `PERM_${index + 1}`
    }));
    const res = await api.post('/api/v1/filter/role/getList');
    const roles = res.data.data;
    const roleMap = roles.reduce((map, role) => {
        map[role.id] = role.roleName;
        return map;
    }, {});

    return permissions.map(permission => ({
        ...permission,
        allowedRoles: permission.allowedRoles.map(roleId => ({
            id: parseInt(roleId, 10),
            label: roleMap[roleId]
        }))
    }));
};

export default getPermissionData