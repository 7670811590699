import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import Stack from '@mui/material/Stack';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import Autocomplete from '@mui/material/Autocomplete';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import { getAgentList } from '../../StateMgmt/Actions/reportActions';
import { updateCallDetails } from '../../StateMgmt/Actions/reportActions';
import { successResponse } from '../../utils/utils';
import { hasPermission } from '../../utils/utils';



const AssignToInputs = (props) => {

    const { setOpenPopup, status, id, setExpanded } = props;

    // const features = useSelector((state) => state.features);
    const features = JSON.parse(localStorage.getItem('features'))||[];

    const [agentOptions, setAgentOptions] = useState([]);
    const [technicianId, setTechnicianId] = useState(null);

    useEffect(() => {
        const { id } = props;
        getAgentList(id).then(({ payload }) => {
            setAgentOptions(payload);
        });
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const handleSelectTechnician = (_, value, reason) => {
        setTechnicianId(value.id);
    }

    const onSubmit = async (data) => {
        data.technicianId = technicianId
        data.callId = id
        data.status = status
        const resData = await updateCallDetails(data);
        successResponse('Call assigned succesfully!');
        setOpenPopup(false)
    }


    return (
        <div className='mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
                <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            error={Boolean(errors.role)}
                            disableClearable
                            onChange={handleSelectTechnician}
                            options={agentOptions}
                            renderInput={(params) => (
                                <CustomizeTextField
                                    {...params}
                                    label='Call Assigned To'
                                    // value={assignedTo}
                                    name='technicianId'
                                    {...register("technicianId", { required: 'Please select technician' })}
                                    error={Boolean(errors.technicianId)}
                                    helperText={errors.technicianId?.message}
                                />
                            )}
                        />
                    </Stack>
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter Charges'
                        variant='outlined'
                        label='Customer Charges'
                        name='charge'
                        {...register("charge", { required: 'Agent Charge is required.' })}
                        error={Boolean(errors.charge)}
                        helperText={errors.charge?.message}
                    />
                </div>
                <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                    {status && <BtnDefault
                        disabled={!hasPermission(features, 'UPDATE_COMPLAIN')}
                        variant="contained"
                        color="primary"
                        size="medium"
                        text="Save Changes"
                        type="submit"
                    />}
                    {!status && <BtnDefault
                        disabled={!hasPermission(features, 'UPDATE_COMPLAIN')}
                        variant="contained"
                        color="primary"
                        size="medium"
                        text="Assign Complaint"
                        type="submit"
                    />}
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Cancel"
                        border='#016fe2'
                        onClick={() => setOpenPopup(false)}
                    />
                </div>

            </form>
        </div>

    )
}

export default AssignToInputs


