import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import { getMonthlyTrendBar } from '../../StateMgmt/Actions/StatChartActions';

const MonthlyTrendBar = () => {
    const [trendData, setTrendData] = useState([])
    useEffect(() => {
        getMonthlyTrendBar().then(({ payload }) => {
            setTrendData(payload);
        });
    }, []);
    const options = {
        isStacked: true,
        chart: {
            title: "Monthly Trends",
            subtitle: "Received Calls, Attended Calls, Closed Calls, Due Calls",
        },
    };
    return (
        <>
            <Chart
                chartType="ColumnChart"
                width="100%"
                height="400px"
                data={trendData}
                options={options}
                // spreadSheetUrl="https://docs.google.com/spreadsheets/d/1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA"
                // toolbarItems={[
                //     {
                //         type: "csv",
                //         datasource:
                //             "https://spreadsheets.google.com/tq?key=1jN0iw0usssnsG1_oi-NXtuKfsUsGme09GsFidbqxFYA",
                //     },
                // ]}
            />
        </>

    )
}
export default MonthlyTrendBar

// const data = [
//     ["Months", "Received", "Attended", "Closed", "Due"],
//     ["Jan", 1000, 400, 200, 100],
//     ["Feb", 1170, 460, 250, 230],
//     ["Mar", 660, 1120, 300, 375],
//     ["Apr", 1030, 540, 350, 240],
//     ["May", 1000, 400, 200, 100],
//     ["Jun", 1170, 460, 250, 230],
//     ["Jul", 660, 1120, 300, 375],
//     ["Aug", 1030, 540, 350, 240],
//     ["Sep", 1000, 400, 200, 100],
//     ["Oct", 1170, 460, 250, 230],
//     ["Nov", 660, 1120, 300, 375],
//     ["Dec", 1030, 540, 350, 240],
// ];
