import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import Stack from '@mui/material/Stack';
import imageCompression from 'browser-image-compression';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import Autocomplete from '@mui/material/Autocomplete';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import { uploadImage } from '../../CommonFunctions/GetStepperData';
import { hasPermission } from '../../utils/utils';
import { updateTechnicianCallDetails } from '../../StateMgmt/Actions/technicianActions';

const imgComperationOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
}

const parts = [
    {
        id: '1',
        label: "PART PENDING"
    },
    {
        id: '2',
        label: "REPLACEMENT"
    },
    {
        id: '3',
        label: "COMPLETED"
    },
    {
        id: '4',
        label: "REJECTED"
    }
]

const TechnicianInputs = (props) => {
    // const features = useSelector((state) => state.features);
    const features = JSON.parse(localStorage.getItem('features'))||[];
    const { setOpenPopup, status, callId } = props;
    const [value, setValue] = useState(null);
    const [invoiceReader, setInvoiceReader] = useState('');
    const [beforeImageReader, setBeforeImageReader] = useState('');
    const [afterImageReader, setAfterImageReader] = useState('');
    const [invoiceSource, setInvoiceSource] = useState('');
    const [beforeImageSource, setBeforeImageSource] = useState('');
    const [afterImageSource, setAfterImageSource] = useState('');
    const [fileName, setFileName] = useState('');
    const [disable, setDisable] = useState(false);
    const [buttonName, setButtonName] = useState('Save Changes');



    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const handleInvoice = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setInvoiceReader(reader);
        setFileName(file.name)
        previewFile(reader, setInvoiceSource);
    }

    const handleBeforeImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setBeforeImageReader(reader);
        previewFile(reader, setBeforeImageSource);
    }
    const handleAfterImage = (e, flag) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setAfterImageReader(reader);
        previewFile(reader, setAfterImageSource);
    }

    const previewFile = (reader, source) => {
        reader.onloadend = () => {
            source(reader.result)
        }
    }

    const onSubmit = async (data) => {
        setButtonName('Saving Changes...');
        setDisable(true);
        try {
            const reqBody = {
                callId,
                ...data
            }
            if (invoiceReader) {
                let invoiceFile = invoiceReader;
                if (invoiceReader.type.includes('image')) {
                    invoiceFile = await imageCompression(invoiceReader, imgComperationOptions);
                }
                const { imageUrl: invoice } = await uploadImage(invoiceFile, 'invoice');
                reqBody.invoice = invoice
            }
            if (beforeImageReader) {
                let beforeImg = beforeImageReader;
                if (beforeImageReader.type.includes('image')) {
                    beforeImg = await imageCompression(beforeImageReader, imgComperationOptions);
                }
                const { imageUrl: beforeImage } = await uploadImage(beforeImg, 'beforeImage');
                reqBody.beforeImage = beforeImage
            }
            if (afterImageReader) {
                let afterImg = afterImageReader;
                if (afterImageReader.type.includes('image')) {
                    afterImg = await imageCompression(afterImageReader, imgComperationOptions);
                }
                const { imageUrl: afterImage } = await uploadImage(afterImg, 'afterImage');
                reqBody.afterImage = afterImage

            }
            await updateTechnicianCallDetails(reqBody);
            setOpenPopup(false)
        } catch (error) {
            console.log(error);
        }
        setDisable(false);
        setButtonName('Save Changes');
    }


    return (
        <div className='mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
                <div className="grid gap-4 md:grid-cols-3 grid-cols-1 gap-x-2 ">

                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            error={Boolean(errors.status)}
                            disableClearable
                            // onChange={handleSelectRole}
                            options={parts}
                            renderInput={(params) => (
                                <CustomizeTextField
                                    {...params}
                                    label='Status'
                                    // value={assignedTo}
                                    name='status'
                                    {...register("status", { required: 'Please select a call status' })}
                                    error={Boolean(errors.status)}
                                    helperText={errors.status?.message}
                                />
                            )}
                        />
                    </Stack>
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter Area Affected'
                        variant='outlined'
                        label='Affected Area'
                        name='affectedArea'
                        {...register("affectedArea")}
                    />
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter Problem Observed'
                        variant='outlined'
                        label='Problem Observed'
                        name='probleamObserved'
                        {...register("probleamObserved")}
                    />
                </div>
                <div className="grid gap-4 md:grid-cols-3 grid-cols-1 gap-x-2 ">

                    <Stack spacing={2} sx={{ color: '#851851' }} className='w-full'>


                        <div class="flex items-center justify-start w-full">
                            <label for="dropzone-file1" class="flex flex-col items-start justify-center pl-4 w-full h-16 border-2 border-gray-300 hover:border-[#016FE2] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex justify-start gap-2 pt-8 pb-6">
                                    <svg aria-hidden="true" class="w-8 h-8 mb-3 text-[#016FE2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                    </svg>
                                    <div className='flex flex-col'>
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Invoice</span></p>
                                        <p class="mb-2 text-xs text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                    </div>
                                    {invoiceSource && (
                                        <div className='mb-2'>
                                            <span>{fileName}</span>
                                        </div>
                                    )}
                                </div>
                                <input
                                    id="dropzone-file1"
                                    type="file"
                                    class="hidden"
                                    onChange={(e) => {
                                        handleInvoice(e)
                                        setInvoiceReader(e.target.files[0]);
                                    }}
                                />
                            </label>

                        </div>
                        {/* <p class="text-xs text-end text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p> */}
                    </Stack>
                    <Stack spacing={2} sx={{ color: '#851851' }} className='w-full'>


                        <div class="flex items-center justify-start w-full">
                            <label for="dropzone-file2" class="flex flex-col items-start justify-center pl-4 w-full h-16 border-2 border-gray-300 hover:border-[#016FE2] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex justify-start gap-2 pt-8 pb-6">
                                    <svg aria-hidden="true" class="w-8 h-8 mb-3 text-[#016FE2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                    </svg>
                                    <div className='flex flex-col'>
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Image Before Service</span></p>
                                        <p class="mb-2 text-xs text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                    </div>
                                    {beforeImageSource && (
                                        <div className='bg-themeBlue-5 mb-2'>
                                            <img className='w-16 h-12' src={beforeImageSource} alt='image' />
                                        </div>
                                    )}
                                </div>
                                <input
                                    id="dropzone-file2"
                                    type="file"
                                    class="hidden"
                                    onChange={(e) => {
                                        handleBeforeImage(e)
                                        setBeforeImageReader(e.target.files[0]);
                                    }}
                                />
                            </label>

                        </div>
                        {/* <p class="text-xs text-end text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p> */}
                    </Stack>
                    <Stack spacing={2} sx={{ color: '#851851' }} className='w-full'>


                        <div class="flex items-center justify-start w-full">
                            <label for="dropzone-file3" class="flex flex-col items-start justify-center pl-4 w-full h-16 border-2 border-gray-300 hover:border-[#016FE2] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                <div className="flex justify-start gap-2 pt-8 pb-6">
                                    <svg aria-hidden="true" class="w-8 h-8 mb-3 text-[#016FE2]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                    </svg>
                                    <div className='flex flex-col'>
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Image After Service</span></p>
                                        <p class="mb-2 text-xs text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                    </div>
                                    {afterImageSource && (
                                        <div className='bg-themeBlue-5 mb-2'>
                                            <img className='w-16 h-12' src={afterImageSource} alt='image' />
                                        </div>
                                    )}
                                </div>
                                <input
                                    id="dropzone-file3"
                                    type="file"
                                    class="hidden"
                                    onChange={(e) => {
                                        handleAfterImage(e)
                                        setAfterImageReader(e.target.files[0]);
                                    }}
                                />
                            </label>

                        </div>
                        {/* <p class="text-xs text-end text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p> */}
                    </Stack>
                </div>
                <div className="grid gap-4 md:grid-cols-2 grid-cols-1 gap-x-8 ">
                    <BtnDefault
                        variant="contained"
                        color="primary"
                        size="medium"
                        text={buttonName}
                        disabled={disable && !hasPermission(features, 'UPDATE_COMPLAIN_STATE')}
                        type="submit"
                    />
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Cancel"
                        border='#016fe2'
                        onClick={() => setOpenPopup(false)}
                    />
                </div>

            </form>
        </div>

    )
}

export default TechnicianInputs


