import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { TextField } from '@mui/material';
import api from '../../utils/axios';
import { successResponse } from '../../utils/utils';
import { getFeaturePermissions, getUserPermissions } from '../../StateMgmt/Actions/permissionActions';


const PINLogin = () => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const location = useLocation();
    let phone = location?.state?.phone || '';
    let id = location?.state?.user_id || '';

    const navigate = useNavigate();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }
    const onSubmit = (data) => {
        api.post('/api/v1/auth/user/validatePin', { userId: id, pin: data.pin, phoneNo: phone })
            .then(async (response) => {
                
                const { token, permissions, features,  ...userData } = response.data.data;
                localStorage.setItem('token', token);
                localStorage.setItem('userData', JSON.stringify(userData));
                localStorage.setItem('permissions', JSON.stringify(permissions));
                localStorage.setItem('features', JSON.stringify(features));
                return response;
            })
            .then(async (response) => {
                    if (response.data.data.token) {
                        const { token, ...userData } = response.data.data;
                        // localStorage.setItem('token', token);
                        // localStorage.setItem('userData', JSON.stringify(userData));
                        successResponse('Login Successfull!');
                        if (userData.role === 'Technician') {
                            navigate('/technician');
                        } else
                            if (userData.role === 'Customer Executive') {
                                navigate('/cust_complaints');
                            } else {
                                if (userData.isProfileCompleted) {
                                    // dispatch(getUserPermissions());
                                    setTimeout(()=>{
                                        navigate('/home');
                                    }, 500)
                                } else {
                                    navigate('/ent_details');
                                }
                            }
                    }
                });
    }

    return (
        <div className='grid h-screen w-full'>
            <div className='flex flex-col justify-center pt-64'>
                <form onSubmit={handleSubmit(onSubmit)} className='max-w-[400px] w-full mx-auto bg-gray-50 shadow-xl shadow-slate-400 p-8 px-8 rounded-lg'>
                    <h2 className='text-3xl text-slate-800 font-bold text-center pb-5'>SIGN IN</h2>
                    <div className='flex flex-col text-gray-600 py-2'>
                        <label>Phone Number</label>

                        <TextField
                            className='rounded-lg bg-gray-200 border mt-2 p-2 focus:border-blue-300 focus:bg-blue-50 focus:outline-none'
                            type='text'
                            placeholder='Enter Your Mobile Number'
                            value={phone}
                        />
                    </div>
                    <button onClick={() => { navigate(-1) }}
                        className='w-full my-5 py-2 bg-themeBlue-1	 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/30 text-white font-semibold rounded-lg'
                    >
                        Go Back
                    </button>
                    <div className='flex flex-col text-gray-600 py-2'>
                        <label>PIN</label>
                        <TextField
                            className='rounded-lg bg-gray-200 border mt-2 p-2 focus:border-blue-300 focus:bg-blue-50 focus:outline-none'
                            type='text'
                            placeholder='Enter 6 digit PIN'
                            // onChange={(e) => setPhone(e.target.value)}
                            onKeyDown={handleKeyDown}
                            name='pin'
                            {...register("pin", { required: 'Please enter PIN.' })}
                            error={Boolean(errors.pin)}
                            helperText={errors.pin?.message}
                        />
                    </div>
                    <button
                        className='w-full my-5 py-2 bg-themeBlue-1	 shadow-lg shadow-blue-500/50 hover:shadow-blue-500/30 text-white font-semibold rounded-lg'
                        type="submit"
                    >
                        Sign In
                    </button>
                    <div className='flex justify-between text-gray-400 py-2'>
                        <p className='flex items-center'><input className='mr-2' type='checkbox' /> Remember Me</p>
                        <p onClick={() => {
                            navigate('/reset_pin', {
                                state: {
                                    phone: phone,
                                }
                            })
                        }} className='text-red-600 cursor-pointer'>Forgot PIN ?</p>
                    </div>
                </form>
            </div>
        </div>
    )
}


export default PINLogin;
