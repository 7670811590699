import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HiOutlineUserGroup } from "react-icons/hi";
import { Box } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import SectionHeader from '../Components/Headers/SectionHeader';
import ComplaintActions from '../Components/ComplaintActions';
import ComplaintStatus from '../Components/ComplaintStatus';

import ModalOpener from '../Components/ModalOpener';
import complaints from '../lib/complaints';
import gridStyle from '../lib/Styles/grid_style';
import { getComplaintList } from '../StateMgmt/Actions/complaintActions';
import moment from 'moment';
import { hasPermission } from '../utils/utils';


const Complaints = () => {
    // const features = useSelector((state) => state.features);
    const features = JSON.parse(localStorage.getItem('features'))||[];
    const [complaintList, setComplaintList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [rowId, setRowId] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [status, setStatus] = useState('');
    const [rowData, setRowData] = useState({});
    const [filter, setFilter] = useState([]);
    const [complaintFilter, setComplaintFilter] = useState('');
    const endDate = localStorage.getItem('endingDate');
    const startDate = localStorage.getItem('startingDate');
    const temp = localStorage.getItem('selectedStatus');
    const savedSatus = temp ? JSON.parse(temp) : [];
    const tempStatusFilter = JSON.stringify({
        status: savedSatus.map(x => x.label),
        visitDateTime: {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
        }
    });
    const [statusFilter, setStatusFilter] = useState(tempStatusFilter);

    useEffect(() => {
        getComplaintList(complaintFilter, statusFilter).then(({ payload }) => {
            setComplaintList(payload)
        });
    }, [openPopup, complaintFilter, statusFilter])

    const columns = useMemo(() => [
        // {
        //     field: 'photoURL',
        //     headerName: '',
        //     width: 80,
        //     renderCell: params => <Avatar alt="Remy Sharp" src={params.row.photoURL} />,
        //     sortable: false,
        //     filterable: false,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: 'callId',
            headerName: 'Call ID',
            width: 100,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'createdDate',
            headerName: 'Created On',
            width: 180,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'customerName',
            headerName: 'Customer Name',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 300,
            headerAlign: 'center',
            align: 'center'
        },
        // {
        //     field: 'serviceType',
        //     headerName: 'Type of Service',
        //     width: 200,
        //     headerAlign: 'center',
        //     align: 'center'
        // },
        {
            field: 'visitDateTime',
            headerName: 'Service Time',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 180,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => <ComplaintStatus {...{ params }} />,
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'actions',
            headerName: 'Action',
            width: 140,
            renderCell: params =>
                <ComplaintActions
                    {...{
                        params,
                        setOpenPopup,
                        setAction,
                        setStatus,
                        flag: 'Complaint',
                        disableOptions: {
                            view: hasPermission(features, 'READ_COMPLAIN'),
                            edit: hasPermission(features, 'UPDATE_COMPLAIN')
                        }
                    }}
                />,
            headerAlign: 'center',
            headerClassName: 'table--header',
            align: 'center'
        },
    ], [rowId])
    // rowId is passed as a dependency in useMemo since we've to render the btn if there is a change in the row

    return (

        <>
            <SectionHeader
                icon={<HiOutlineUserGroup />}
                headingText='Calls / Complaints'
                setFilter={setFilter}
                data={complaintList}
                searchLabel='Search Complaint'
                column='status'
                addBtnFlag={false}
                searchFlag={true}
                filter={setComplaintFilter}
                filterFlag={true}
                exportFlag={true}
                setAction={setAction}
                setOpenPopup={setOpenPopup}
                complaintFilter={complaintFilter}
                statusFilter={statusFilter}
            />


            <Box
                sx={gridStyle}>
                <DataGrid
                    sx={dataGridStyle}
                    columns={columns}
                    rows={complaintList}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowSpacing={params => ({
                        top: params.isFirstVisible ? 0 : 5,
                        bottom: params.isLastVisible ? 0 : 5
                    })}
                    onCellEditCommit={params => setRowId(params.id)}
                    onRowClick={params => { setRowId(params.id); setRowData(params.row) }}
                    disableColumnMenu={true}
                    filterModel={{
                        items: filter
                    }}
                    disableSelectionOnClick="false"
                    getRowClassName={(params) =>
                        params.row.isDue ? "due" : "accomplish"
                    }
                />
            </Box>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={rowData}
                    id={rowId}
                    status={status}
                    setStatusFilter={setStatusFilter}

                />

            }

        </>
    )
}

export default Complaints;

const dataGridStyle = {
    // [`& .${gridClasses.row}.due`]: {
    //     color: "#f06666",
    // },
    // [`& .${gridClasses.row}.accomplish`]: {
    //     backgroundColor: "#80c080",
    // },

    ["& .assignStatus--cell"]: {
        color: "#f06666",
        fontWeight: "600",
        textAlign: "center",
    },
};

