import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import { styled } from '@mui/material/styles';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, FormHelperText } from '@mui/material';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import { updateCallDetails } from '../../StateMgmt/Actions/reportActions';
import { successResponse } from '../../utils/utils';
import GetOpenCallFormOptions from '../../CommonFunctions/GetOpenCallFormOptions';
import _ from 'lodash';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { hasPermission } from '../../utils/utils';



const ClosingInputs = (props) => {

    // const features = useSelector((state) => state.features);
    const features = JSON.parse(localStorage.getItem('features'))||[];
    const { setOpenPopup, status, id, productAndBilling, technician, complainAndStatus } = props;
    const { product, brandName, noOfProduct, billStatus, dateOfPurchase, dealerName, productId } = productAndBilling;
    const { natureOfComplain } = complainAndStatus;
    const { charge } = technician;
    const [value, setValue] = useState(null);
    const [productValue, setProductValue] = useState(product);
    const [billStatusValue, setBillStatusValue] = useState(billStatus);
    const [complaintValue, setComplaintValue] = useState(natureOfComplain);
    const [productQuantityValue, setProductQuantityValue] = useState(noOfProduct);
    const [dealerNameValue, setDealerNameValue] = useState(dealerName);
    const [chargeValue, setChargeValue] = useState(charge);
    const [natureOfComplaint, setNatureOfComplaint] = useState([]);
    const [billStatusInput, setBillStatusInput] = useState([]);
    const [productIdValue, setProductIdValue] = useState(productId);
    const [productList, setProductList] = useState([]);
    const [query, setQuery] = useState('');
    const [dopValue, setDopValue] = useState(dayjs());

    useEffect(() => {
        GetOpenCallFormOptions(query).then(({ billSatus, natureOfComplain, product }) => {
            setBillStatusInput(billSatus)
            setNatureOfComplaint(natureOfComplain)
            setProductList(product)
        });
    }, [query])

    const handleSelectProduct = (_, value, reason) => {
        console.log(value);
        setProductIdValue(value.id);
    }

    const handleSelectComplaintNature = (_, value, reason) => {
        console.log(value);
    }

    const handleSelectBillStatus = (_, value, reason) => {
        console.log(value);
    }

    const handleAutocompleteTextChange = (event) => {
        const productQuery = event.target.value;
        setProductList([])
        setQuery(productQuery);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();


    const onSubmit = async (data) => {
        data.callId = id
        data.status = status
        data.productId = productIdValue
        data.billStatus = billStatusValue
        data.natureOfComplain = complaintValue
        const resData = await updateCallDetails(data);
        successResponse('Call closed succesfully');
        setOpenPopup(false)
    }


    return (
        <div className='mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
                <div className="grid gap-4 md:grid-cols-1 grid-cols-1 gap-x-8 ">
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            defaultValue={productValue}
                            disableClearable
                            onChange={handleSelectProduct}
                            options={productList}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    label='Select Product'
                                    name='product'
                                    {...register("product", { required: 'Please select a product' })}
                                    onChange={handleAutocompleteTextChange}
                                />
                            )}

                        />
                    </Stack>
                </div>
                <div className="grid gap-4 md:grid-cols-3 grid-cols-1 gap-x-8 ">
                    <CustomTextField
                        className='w-full'
                        defaultValue={productQuantityValue}
                        placeholder='Number of Products'
                        variant='outlined'
                        label='Number of Products'
                        name='noOfProduct'
                        {...register("noOfProduct")}
                    />
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            disableClearable
                            defaultValue={complaintValue}
                            onChange={(e, value) => { setComplaintValue(value.label) }}
                            options={natureOfComplaint}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    label='Nature of Complaint'
                                    name='natureOfComplain'
                                />
                            )}
                        // {...register("natureOfComplain")}

                        />
                    </Stack>
                    <CustomTextField
                        className='w-full'
                        defaultValue={chargeValue}
                        placeholder='Customer Charges'
                        variant='outlined'
                        label='Customer Charges'
                        name='customerCharges'
                        {...register("customerCharges")}
                    />
                </div>
                <div className="grid gap-4 md:grid-cols-3 grid-cols-1 gap-x-8 ">
                    <CustomizeTextField
                        className='w-full'
                        defaultValue={dealerNameValue}
                        placeholder='Dealer Name'
                        variant='outlined'
                        label='Dealer Name'
                        name='dealerName'
                        {...register("dealerName")}
                    />
                    <Stack spacing={2} sx={{ color: '#851851' }}>
                        <Autocomplete
                            disableClearable
                            options={billStatusInput}
                            defaultValue={billStatusValue}
                            onChange={(e, value) => { setBillStatusValue(value.label) }}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    label='Bill Status'
                                    name='billStatus'
                                />
                            )}
                        // {...register("billStatus")}

                        />
                    </Stack>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Date of Purchase"
                            value={dopValue}
                            format="DD-MM-YYYY"
                            onChange={(newValue) => {
                                setDopValue(newValue);
                            }}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    name='dateOfPurchase'
                                    {...register("dateOfPurchase")}
                                />
                            )}
                            maxDate={dayjs()}
                        />
                    </LocalizationProvider>
                </div>
                <div className="grid gap-4 md:grid-cols-3 grid-cols-1 gap-x-8 ">
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter TA Amount'
                        variant='outlined'
                        label='TA Amount'
                        name='tAamt'
                        {...register("tAamt", { required: 'Please enter TA amount.' })}
                        error={Boolean(errors.tAamt)}
                        helperText={errors.tAamt?.message}
                    />
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter DA Amount'
                        variant='outlined'
                        label='DA Amount'
                        name='dAamt'
                        {...register("dAamt", { required: 'Please enter DA amount.' })}
                        error={Boolean(errors.dAamt)}
                        helperText={errors.dAamt?.message}
                    />
                    <CustomizeTextField
                        className='w-full'
                        placeholder='Enter Technician Fee'
                        variant='outlined'
                        label='Technician Fee'
                        name='technicalFee'
                        {...register("technicalFee", { required: 'Please techincian fee.' })}
                        error={Boolean(errors.technicalFee)}
                        helperText={errors.technicalFee?.message}
                    />
                </div>
                <div className="grid gap-4 grid-cols-2 md:gap-x-8 gap-x-3">
                    <BtnDefault
                        disabled={!hasPermission(features, 'UPDATE_COMPLAIN')}
                        variant="contained"
                        color="primary"
                        size="medium"
                        text="Close Call"
                        type="submit"
                    />
                    <BtnOutlined
                        variant="outlined"
                        color="error"
                        size="medium"
                        text="Cancel"
                        border='#016fe2'
                        onClick={() => setOpenPopup(false)}
                    />
                </div>

            </form>
        </div>

    )
}

export default ClosingInputs

const CustomTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#153b8c',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#153b8c',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#016fe2',
        },
        '&:hover fieldset': {
            borderColor: '#153b8c',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#153b8c',
        },
    },
});


