import React, { Fragment, useEffect, useState } from 'react';
import _, { template } from 'lodash';
import { Popover, Transition, Menu } from '@headlessui/react';
import { Avatar } from '@mui/material';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../utils/utils'
import api from "../utils/axios";
import moment from 'moment';


const Popup = (tooltip, eventHandler, reload) => {
    const navigate = useNavigate();
    const user = getUser();
    const [planName, SetPlanName] = useState('');
    const [planExp, SetPlanExp] = useState('');
    const [qrCount, SetQrCount] = useState({used: 0, limit: 0});
    const [complaintCount, SetComplaintCount] = useState({used: 0, limit: 0});
    const tempFn = () => {
        api.get('/api/v1/user/getActivePlan').then((data)=>{
            const {plan, validUpto, complaintCount, qrRegistrationCount} = data.data.data;
            SetPlanName(plan);
            SetPlanExp(validUpto);
            SetQrCount(qrRegistrationCount);
            SetComplaintCount(complaintCount);
        });
    };
    return (
        <Menu as="div" className="z-10">
            <div className='inline-flex'>
                <Menu.Button className="ml-2 inline-flex rounded-full hover:ring-2 focus:outline-none focus:ring-2 focus:ring-[#2f7ed1]">
                    <span className='sr-only'>Open User Menu</span>
                    <div
                        className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                        style={{ backgroundImage: 'url("https://source.unsplash.com/80*80?face")' }}
                        onClick={() => tempFn()}
                    >
                        <Avatar src={user?.profileImg}>{stringAvatar(user?.name)}</Avatar>
                        <span className='sr-only'>Hugh Jackson</span>
            
                    </div>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                        {({ active }) => (
                            <div
                                // onClick={() => navigate(`/profile/${user.userId}`)}
                                onClick={() => navigate('/profile')}
                                className={classNames(
                                    active && "bg-gray-100",
                                    "text-gray-700 focus:bg-gray-200 cursor-pointer rounded-sm px-4 py-2"
                                )}
                            >
                                Your Profile
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <div
                                onClick={() => navigate('/settings')}
                                className={classNames(
                                    active && "bg-gray-100",
                                    "text-gray-700 focus:bg-gray-200 cursor-pointer rounded-sm px-4 py-2"
                                )}
                            >
                                Settings
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <div
                                onClick={() => { localStorage.clear(); navigate('/login'); }}
                                className={classNames(
                                    active && "bg-gray-100",
                                    "text-gray-700 focus:bg-gray-200 cursor-pointer rounded-sm px-4 py-2"
                                )}
                            >
                                Logout
                            </div>
                        )}
                    </Menu.Item>
                    <Menu.Item disabled>
                        {({ active }) => (
                            <div
                                className={classNames(
                                    active && "bg-gray-100",
                                    "text-center border-2 round"
                                )}
                            >

                                <div className='bg-black text-white round bold'>{planName}{' Plan'}</div>
                                <div className=''>{'Valid Upto: '} {moment(planExp).format('DD MMM YYYY')}</div>
                                <div className=''>{'Complaint Usage: '} {complaintCount.used}{'/'}{complaintCount.limit}</div>
                                <div className=''>{'QR Usage: '} {qrCount.limit > 0 ? `${qrCount.used} / ${qrCount.limit}`: 'NA'}</div>
                            </div>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>

    )
}

const stringAvatar = (name) => {
    if (!name) return;
    const regex = /\s+/gi;
    const nameLength = name?.trim()?.replace(regex, ' ')?.split(' ')?.length;
    return nameLength > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name[0]}${name[1]}`;
}

export default Popup