import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SectionHeader from '../Components/Headers/SectionHeader';
import { HiOutlineCollection } from "react-icons/hi";
import { VscSettings } from "react-icons/vsc";
import { MdQrCodeScanner } from "react-icons/md";
import BtnOutlined, { BtnDefault } from '../Components/BtnComponent';
import ModalOpener from '../Components/ModalOpener';
import getProductData from '../CommonFunctions/getProductData';
import { GetAllProducts, deleteProduct } from '../StateMgmt/Actions/productActions';
import { hasPermission } from '../utils/utils';


const Products = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    // const features = useSelector((state) => state.features);
    const features = JSON.parse(localStorage.getItem('features'))||[];
    const [product, setProduct] = useState([]);
    const [productData, setProductData] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [productId, setproductId] = useState('');
    const [readMore, setReadMore] = useState(false);
    const [productFilter, setProductFilter] = useState('');
    const linkName = readMore ? 'Read Less << ' : 'Read More >> '


    useEffect(() => {
        GetAllProducts(productFilter).then((data) => {
            setProduct(data.payload.data)
            dispatch(product)
        });
    }, [openPopup, productFilter])


    return (
        <div className=''>
            <SectionHeader
                icon={<HiOutlineCollection />}
                headingText='Products'
                btnText="Add Product"
                searchFlag={false}
                addBtnFlag={true}
                addSetting={false}
                productBrand={false}
                setOpenPopup={setOpenPopup}
                setAction={setAction}
                productSearch={true}
                setProductFilter={setProductFilter}
                action='AddProduct'
                disable={hasPermission(features, 'ADD_PRODUCT')}
                categoryAction='CreateCategory'
                settingIcon={<VscSettings />}
                categoryText="Product Categories"
                brandText="Product Brands"
            />
            <div className='flex items-center justify-center min-h-fit container mx-auto mt-5 '>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 gap-y-10 overflow-scroll h-screen'>
                    {product.map((item, i) => (
                        <div key={i} className='card bg-white shadow-themeBlue-4 shadow-md hover:bg-themeBlue-3 hover:shadow-lg hover:shadow-themeBlue-5'>
                            <div className='p-5 flex flex-col gap-3'>
                                <div className='rounded-xl overflow-hidden'>
                                    <span className='pl-2 relative top-10 text-4xl'>
                                        {hasPermission(features, 'PRODUCT_QR') &&
                                            <QrCodeScannerIcon onClick={() => window.open(`/productQRData?productId=${item.productId}&&productName=${item.subCategoryName}`)} fontSize='large' sx={{ background: 'white' }} />
                                        }
                                    </span>
                                    <img src={item.image} className='object-cover h-44 w-full' alt='' />
                                </div>
                                <div className='flex flex-col h-52 space-y-5 justify-between'>
                                    {/* <div className='grid grid-cols-6 place-items-center'>
                                        <span className='col-span-2 text-md md:text-md font-bold mt-3'>Brand</span>
                                        <span className='text-md md:text-md font-medium mt-3'>:</span>
                                        <span className='col-span-3 text-md md:text-md font-medium mt-3'>{item.brandName}</span>
                                    </div>
                                    <div className='grid grid-cols-6 place-items-center'>
                                        <span className='col-span-2 text-md md:text-md font-bold'>Name</span>
                                        <span className='text-md md:text-md font-medium'>:</span>
                                        <span className='col-span-3 text-md md:text-md font-medium'>{item.rangeName}</span>
                                    </div>
                                    <div className='grid grid-cols-6 place-items-center'>
                                        <span className='col-span-2 text-md md:text-md font-bold'>Category</span>
                                        <span className='text-md md:text-md font-medium'>:</span>
                                        <span className='col-span-3 text-md md:text-md font-medium'>{item.categoryName}</span>
                                    </div> */}
                                    <div className='grid grid-cols-6 place-items-center'>
                                        {/* <span className='col-span-2 text-md md:text-md font-bold'>Sub Category</span>
                                        <span className='text-md md:text-md font-medium'>:</span> */}
                                        <span className='col-span-6 text-md md:text-md font-bold py-6 text-center max-h-[2rem]'>
                                            {/* <a className="read-more-link" onClick={() => { setReadMore(!readMore) }}>
                                                <h2 className='text-xs text-themeBlue-1 cursor-pointer'>{linkName}</h2>
                                            </a> */}
                                            {/* {readMore && item.subCategoryName} */}
                                            {item.subCategoryName} / {item.rangeName}
                                        </span>
                                    </div>
                                    {/* <div className='grid grid-cols-6 place-items-center'>
                                        <span className='col-span-2 text-md md:text-md font-bold'>Warranty</span>
                                        <span className='text-md md:text-md font-medium'>:</span>
                                        <span className='col-span-3 text-md md:text-md font-medium'>{item.warranty}</span>
                                    </div> */}
                                    <div className='flex gap-4 justify-center '>
                                        <BtnDefault
                                            disabled={!hasPermission(features, 'EDIT_PRODUCT')}
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            text="Edit Product"
                                            onClick={() => {
                                                setAction('EditProduct');
                                                getProductData(item.productId).then(({ categoryName, subCategoryName, rangeName, brandName, serviceOffered, warranty, image, ProductRangeId }) => {
                                                    setProductData({ categoryName, subCategoryName, rangeName, brandName, serviceOffered, warranty, image, id: item.productId, ProductRangeId })
                                                    setOpenPopup(true);
                                                });
                                            }}
                                        />
                                        <BtnOutlined
                                            disabled={!hasPermission(features, 'DELETE_PRODUCT')}
                                            variant="outlined"
                                            color="error"
                                            size="medium"
                                            text="Remove Product"
                                            onClick={() => deleteProduct(item.productId)}
                                        />
                                        {/* <span className='relative text-6xl'>
                                        <MdQrCodeScanner onClick={() => window.open('http://www.google.com/')} />
                                    </span> */}
                                    </div>
                                </div>


                            </div>
                        </div>
                    ))}
                </div>

                {action &&
                    <ModalOpener
                        action={action}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        data={productData}
                    // data={rowData}
                    />
                }
            </div>
        </div>

    )
}

export default Products

