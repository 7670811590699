import React, { useState } from 'react';
import { Avatar } from '@mui/material';
import { GoIssueOpened, GoIssueClosed } from "react-icons/go";
import { BsFillPersonFill, BsFillTelephoneFill, BsTelephonePlusFill } from "react-icons/bs";
import { MdAlternateEmail } from "react-icons/md";
import { BtnDefault } from './BtnComponent';
import ModalOpener from './ModalOpener';
import { errorMessage } from '../utils/utils';
import { useSelector } from 'react-redux';
import { hasPermission } from '../utils/utils';




const DetailCard = ({ cardData, selectedProduct, setProductInvoice }) => {

    // const features = useSelector((state) => state.features);
    const features = JSON.parse(localStorage.getItem('features'))||[];
    const { name, phoneNumber, openCalls, closedCalls, customerId } = cardData
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    return (
        <div className=' flex flex-col content-center bg-white rounded-md mx-4 mt-3 p-4 border border-themeBlue-1'>
            <div className='w-full grid grid-cols-3 gap-4'>
                <div className='flex flex-col items-center'>
                    <div className='flex gap-4'>
                        <div className="flex items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'><BsFillPersonFill /></div>
                            <div className=''>Name</div>
                        </div>
                        <div className='flex items-center m-2  '>
                            <div className='text-zinc-700 font-semibold'>{name}</div>
                        </div>
                    </div>
                    {/* <div className='flex gap-4 pl-14'>
                        <div className="flex items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <MdAlternateEmail /></div>
                            <div className=''>Email</div>
                        </div>
                        <div className='flex items-center m-2'>
                            <div className='text-zinc-700 font-semibold'>
                                eric@dummyuser.com
                            </div>
                        </div>
                    </div> */}
                    <div className='flex gap-4'>
                        <div className="flex items-center gap-3 m-2 text-gray-600 ">
                            <div className='text-themeBlue-1'> <BsFillTelephoneFill /></div>
                            <div className=''>Phone</div>
                        </div>
                        <div className='flex items-center m-2'>
                            <div className='text-zinc-700 font-semibold'>{phoneNumber}</div>
                        </div>
                    </div>

                </div>

                <div className='flex flex-col justify-center items-center'>
                    <div className=''><Avatar {...stringAvatar(name)} /></div>
                    <div className='text-xs text-gray-800 font-bold mt-2'>{customerId}</div>
                </div>

                <div className='flex flex-col items-center gap-3 mt-2'>
                    <div className='flex gap-4 pr-2'>
                        <div className="flex items-center gap-3 text-gray-600 ">
                            <div className='text-themeBlue-1'> <GoIssueOpened /></div>
                            <div className=''>Open Calls</div>
                        </div>
                        <div className='flex items-center'>
                            <div className='text-zinc-700 font-semibold'>{openCalls}</div>
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <div className="flex items-center gap-3 text-gray-600 ">
                            <div className='text-themeBlue-1'> <GoIssueClosed /></div>
                            <div className=''>Closed Calls</div>
                        </div>
                        <div className='flex items-center'>
                            <div className='text-zinc-700 font-semibold'>{closedCalls}</div>
                        </div>
                    </div>
                    {hasPermission(features, 'REGISTER_COMPLAIN') &&
                        <BtnDefault
                            variant="contained"
                            startIcon={<BsTelephonePlusFill />}
                            color="error"
                            size="large"
                            text="Open New Call"
                            onClick={() => {
                                if (!cardData.addresses?.length) {
                                    errorMessage('Please add atleast one service address to initiate complaint');
                                } else {
                                    setOpenPopup(true)
                                    setAction('OpenNewCall')
                                }
                            }}
                        />
                    }
                </div>

            </div>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    data={cardData}
                    selectedProduct={selectedProduct}
                    setAction={setAction}
                    setProductInvoice={setProductInvoice}
                />

            }
        </div>
    )
}

export default DetailCard

const stringAvatar = (name) => {
    if (!name) return;
    const regex = /\s+/gi;
    const nameLength = name.trim().replace(regex, ' ').split(' ').length;

    return {
        sx: {
            backgroundColor: '#153b8c',
            height: 85,
            width: 85
        },
        children: nameLength > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name[0]}${name[1]}`
    };
}